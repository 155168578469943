import * as React from "react"
import styled from 'styled-components'

import Seo from './../components/Seo.js'
import Layout from './../components/layout.js'
import BoxAnimation from './../components/BoxAnimation.js'

const StyledAbout = styled.section`
	display: flex;
	justify-content: space-around;
	align-items: center;

    @media (max-width: 656px) {
		padding: 22.5vh 0;
	}
`

const StyledDescription = styled.article`
	width: 560px;

	@media (max-width: 656px) {
		width: 80%;
	}
`

const StyledParagraph = styled.p`
	font-size: 16px;
	font-weight: 300;
	margin: 0 0 24px 0;
	line-height: 24px;
    text-align: justify;

	&::last-child {
		margin: 0;
	}

	@media (max-width: 656px) {
		font-size: 3.733vw;
		margin: 0 0 6.400vw 0;
		line-height: 6.400vw;
	}
`

const AboutPage = ({ location: { origin } }) => {
	return (
		<>
			<Seo currentPath={origin}/>
			<Layout>
				<StyledAbout>
					<StyledDescription>
						<StyledParagraph>
						Genblock Capital invests exclusively in blockchain and cryptocurrency, with a focus on decentralized finance and metaverse.
						</StyledParagraph>
						<StyledParagraph>
						We have been deeply entrenched in the crypto markets since early 2017, and invest in liquid cryptocurrencies on the secondary market as well as early stage funding rounds.
						</StyledParagraph>
						<StyledParagraph>
						We aim to be powerusers of the project we invest in, and help projects with bootstrapping liquidity, token economics, market making, marketing, and connecting founders with our vast network.
						</StyledParagraph>
					</StyledDescription>
					<BoxAnimation/>
				</StyledAbout>
			</Layout>
		</>
	)
}

export default AboutPage
